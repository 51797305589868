:root{
    --green-100:#4CAF50;
    --green-200:#2a9235;
    --green-300:#0a490a;
    --yellow-100:#FFC107;
    --yellow-200:#916400;
    --text-100:#333333;
    --text-200:#5c5c5c;
    --li-green-100:#e6fbe3;
    --li-green-200:#dcf1d9;
    --li-green-300:#b4c8b1;
    --button: #00ED64;
}


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body{
    overflow-x: hidden;
}

a{
    text-decoration: none;
    color: black;
}

.btn{
    padding: 10px 20px;
    background: var(--button);
    border: 1px solid black;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all .3s ;
}

.btn:hover{
    cursor: pointer;
    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.61) 5px 5px 10px;
}

.btn i{
    font-size: 25px;
}