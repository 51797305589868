.contactSection{
    height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../../assets/bg.jpg) no-repeat center/cover;
}

#contact{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80%;
    width: 80%;
    backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.61) 5px 5px 10px;
    border-radius: 25px;
}

form {
    margin: .6rem auto;
    width: 40rem;
    text-align: center;
    margin-bottom: .3rem;
}

.inputbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.inputbox input,
textarea {
    font-size: .7rem;
    padding: .5rem;
    width: 100%;
    color: var(--text-color);
    background: var(--second-bg-color);
    border-radius: .8rem;
    margin: .3rem 0;
}

.inputbox input {
    width: 49%;
}

textarea {
    resize: none;
    margin-bottom: .3rem;
}

@media screen and (max-width: 768px) {
    form{
        width: 100%;
    }

    .inputbox input, textarea{
        font-size: 1rem;
    }

    #contact{
        padding: 3rem 2rem;
        width: 90%;
        height: fit-content;
    }

    .contactSection{
        background: url(../../assets/bgPhn.jpg) no-repeat center/cover;
    }
}