.categorySection{
    height: 90vh;
    width: 100vw;
    background: url(../../assets/bg.jpg) no-repeat center/cover;
    padding: 2rem 5rem;
}

.categories{
    height: 80vh;
    width: 80vw;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(2,1fr);
}

.categoryCard{
    margin: 0 .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(30px);
    border-radius: 25px;
    height: 36vh;
    width: 27vw;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.6) 5px 5px 10px;
    transition: all .3s;
}

.categoryCard:hover{
    scale: 1.05;
    cursor: pointer;
}

.categoryCard .img{
    background: url(../../assets/Bacteria.jpg) no-repeat center/cover;
    width: 100%;
    height: 40%;
    overflow: hidden;
}

.categoryCard .cardText{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}

.btn{
    padding: .3rem .6rem;
    margin-top: 5px;
}

@media screen and (max-width: 1024px){
    .categorySection{
        background: url(../../assets/bgPhn.jpg) center/cover;
        height: fit-content;
        background-repeat: repeat-y;
    }

    .categoryCard .img{
        height: 50%;
    }

    .categories{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        height: 100%;
    }

    .categoryCard{
        width: 40vw;
        height: 30vh;
    }
}

@media screen and (max-width: 600px) {
    .categories{
        grid-template-columns: repeat(1, 1fr);
    }

    .categoryCard{
        width: 80vw;
        height: 36vh;
    }

    .categoryCard .img{
        height: 60%;
        width: 100%;
    }

    .categoryCard .cardText{
        gap: 1rem;
        margin-top: 10px;
    }

    .btn{
        padding: .5rem 1rem;
    }
}  