.aboutSection{
    height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../../assets/bg.jpg) no-repeat center/cover;
}

.aboutCtr{
    width: 80%;
    height: 80%;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.61) 15px 15px 30px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.buttons{
    display: flex;
    gap: 1rem;
}

.btn{
    padding: 10px 20px;
    background: var(--button);
    border: 1px solid black;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all .3s ;
}

.btn:hover{
    cursor: pointer;
    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.61) 5px 5px 10px;
}

.btn i{
    font-size: 25px;
}

@media screen and (max-width:1024px){
    .aboutSection{
        background: url(../../assets/bgPhn.jpg) no-repeat center/cover;
    }
}

@media screen and (max-width:768px){
    .aboutCtr{
        padding: 1rem;
        width: 85%;
        height: 80%;
    }
}