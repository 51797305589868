.home{
    height: 90vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;
    /* background: var(--li-green-400); */
    background: url(../../assets/bg.jpg) no-repeat center/cover;
}

.homeText{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.homeText>h1{
    font-size: 2.75rem;
}

.homeText .buttons{
    display: flex;
    gap: 1rem;
}

.animated{
    font-size: 2rem;
    font-weight: 600;
}

.homeText .btn{
    padding: 10px 20px;
    background: var(--button);
    border: 1px solid black;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all .3s ;
}

.btn:hover{
    cursor: pointer;
    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.61) 5px 5px 10px;
}

.btn i{
    font-size: 25px;
}

.signIn{
    background: white;
}

.img img{
    filter: drop-shadow(rgba(0, 0, 0, 0.61) 10px 10px 15px);
}

@media screen and (max-width: 1200px){
    html{
        font-size: 85%;
    }

    img{
        width: 32rem;
    }
}

@media screen and (max-width: 1024px) and (min-height: 960px){
    html{
        font-size: 95%;
    }

    img{
        width: 45rem;
    }

    .homeText{
        gap: 1.2rem;
        align-items: center;
    }

    .homeText>div{
        text-align: center;
    }

    .buttons .btn{
        padding: .6rem 1.2rem;
        font-size: 1.5rem;
    }

    .home{
        flex-direction: column-reverse;
        justify-content: center;
        gap: 5rem;
    }
}

@media screen and (max-width: 768px){

    .home{
        background:rgba(0, 0, 0, 0.1) url(../../assets/bgPhn.jpg) no-repeat center/cover;
        background-blend-mode: darken;
    }

    img{
        width: 35rem;
    }

    .homeText{
        gap: 1rem;
        align-items: center;
        text-align: center;
    }

    .homeText>div{
        text-align: center;
    }

    .buttons .btn{
        padding: .4rem .8rem;
        font-size: 1.2rem;
    }

    .home{
        flex-direction: column-reverse;
        justify-content: center;
        gap: 5rem;
    }
}

@media screen and (max-width: 640px){

    html{
        font-size: 80%;
    }

    img{
        width: 25rem;
    }

    .home{
        gap: 3rem;
    }
}

@media screen and (max-width: 380px){

    html{
        font-size: 60%;
    }

    img{
        width: 20rem;
    }

    .home{
        gap: 3rem;
    }
}

