:root {
    --green-100: #4CAF50;
    --green-200: #2a9235;
    --green-300: #0a490a;
    --yellow-100: #FFC107;
    --yellow-200: #916400;
    --text-100: #333333;
    --text-200: #5c5c5c;
    --li-green-100: #e6fbe3;
    --li-green-200: #dcf1d9;
    --li-green-300: #b4c8b1;
    --button: #00ED64;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    width: 100vw;
    height: 10vh;
    background: var(--li-green-200);
    color: black;
    border-bottom: 1px solid black;
}

.navlist {
    display: flex;
    font-weight: 500;
    font-size: 1.2rem;
}

.navlist a {
    padding: 5px 12px;
    border-radius: 30px;
    margin: 0px 0.4rem;
    transition: all .3s ease-in-out;
}

.navlist a:hover {
    background: var(--green-200);
    color: white;
}

.navtext {
    display: flex;
    gap: 1.5rem;
}

.iconMenu,
.close,
.menu,
.navlistPhone {
    display: none;
}

.pathActive {
    background: var(--green-200);
    color: white;
    box-shadow: rgba(0, 0, 0, 0.61) 1px 1px 10px;
}

@media screen and (max-width:768px) {

    .active {
        display: block;
        font-size: 2.5rem;
    }

    .iconMenu {
        display: block;
        font-size: 2.5rem;
        padding-top: 5px;
    }

    .hidden {
        display: none;
    }

    .navlist {
        display: none;
    }

    .menu {
        left: 100%;
        top: 0;
        opacity: 0;
        display: none;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        background: var(--li-green-100);
        /* transition: .3s ease-out; */
        position: absolute;
        z-index: 100;
        animation: menuClose .3s ease-out;
    }

    .activeMenu {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        background: var(--li-green-100);
        opacity: 1;
        top: 0;
        left: 0;
        /* transition: .3s ease-out; */
        animation: menuOpen .3s ease-out;
        z-index: 100;
    }

    @keyframes menuOpen {
        0% {
            opacity: 0;
            display: flex;
            left: 100%;
        }

        100% {
            opacity: 1;
            left: 0;
        }
    }

    @keyframes menuClose {
        100% {
            opacity: 0;
            /* display: flex; */
            left: 100%;
        }

        0% {
            opacity: 1;
            left: 0;
            display: flex;
        }
    }

    .activeMenu .close {
        position: absolute;
        top: 1vh;
        right: 4vw;
        font-size: 4rem;
    }

    .navlistPhone {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 5rem;
    }

    .navlistPhone a {
        padding: 5px 12px;
        border-radius: 30px;
        text-align: center;
        font-weight: 500;
        font-size: 1.5rem;
        transition: all .3s ease-in-out;
        display: none;
    }

    .activeMenu>.navlistPhone a {
        animation: navlistOpen .2s ease-in;
        display: block;
        opacity: 1;
    }

    .activeMenu>.navlistPhone a:nth-child(2) {
        animation-duration: .4s;
    }

    .activeMenu>.navlistPhone a:nth-child(3) {
        animation-duration: .6s;
    }

    .activeMenu>.navlistPhone a:nth-child(4) {
        animation-duration: .8s;
    }

    .navlistPhone a:hover {
        background: var(--green-200);
        color: white;
    }

    @keyframes navlistOpen {
        0% {
            opacity: 0;
            margin-left: 30rem;
        }

        100% {
            margin-left: 0rem;
            opacity: 1;
        }
    }
}