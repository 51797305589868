:root {
    --theme-green: #00ED64;
}

.sign-in-page {
    background: rgba(0, 0, 0, 0.4) url('../../assets/bg.jpg') no-repeat center center/cover;
    background-blend-mode: darken;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign-in-page button,
.sign-in-page input {
    font: inherit;
    border: none;
    outline: none;
}

.container {
    position: relative;
    isolation: isolate;
    width: 40rem;
    padding: 10rem 0;
    color: #fff;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 1px 1rem rgba(0, 0, 0, 0.1);
    scale: .6;
}

.container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 10rem;
    background-color: var(--theme-green);
    border-radius: 1rem 1rem 100% 100%;
}

.form__title {
    position: absolute;
    top: 3.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2.5rem;
}

.form {
    padding: 2.5rem;
    background-color: inherit;
}

.form__group {
    position: relative;
    display: flex;
    align-items: baseline;
    margin-inline: auto;
}

.form__icon {
    font-size: 2rem;
    margin-right: 1rem;
    color: var(--theme-green);
}

.form__input {
    position: relative;
    width: 100%;
    margin-top: 0.5rem;
    padding-block: 1rem;
}

.form__bar {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color:
        rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 1px;
}

.form__bar::before {
    content: '';
    position: absolute;
    background-color: var(--theme-green);
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s;
}

.form__input:focus+.form__bar::before,
.form__input:valid+.form__bar::before {
    transform: scaleX(1);
}

.form__button {
    display: block;
    margin: 2.5rem auto 3rem;
    padding: 1rem 5rem;
    background-color: var(--theme-green);
    color: #fff;
    outline: 2px solid var(--theme-green);
    border-radius: 3rem;
    cursor: pointer;
    transition: .3s;
}

.form__button:hover,
.form__button:focus {
    background-color: var(--theme-green);
    outline-offset: 5px;
}

.form__switch {
    font-size: 1.5rem;
    text-align: center;
    color: #a1a1a1;
}

.form__switch a {
    text-decoration: none;
    color: var(--theme-green);
}

.form__switch a:hover {
    text-decoration: underline;
}

@media screen and (min-width: 43.75em) {
    .container::before {
        height: 30rem;
    }

    .form__title {
        left: 10rem;
        transform: none;
    }

    .form {
        transform: translateX(10rem);
        border-radius: inherit;
        box-shadow: inherit;
    }
}

#signin-btn,
#signup-btn{
    cursor: pointer;
}

.hidden {
    display: none;
}